<template>
  <onboarding-layout back-route-name="Home">
    <div class="login-container">
      <h1 class="h2 mb-2">
        Hi, welkom terug!
      </h1>

      <p class="mb-3 color-text">
        Login om door te gaan.
      </p>

      <form class="form-block" @submit.prevent="login">
        <Errors :errors="errors" />

        <div class="mb-2">
          <label class="visually-hidden" for="email">
            E-mail
          </label>

          <input
            class="form-control form-control--center-placeholder"
            type="email"
            id="email"
            name="email"
            v-model="formInput.email"
            placeholder="Vul hier je mailadres in"
          />
        </div>

        <div class="mb-2">
          <label class="visually-hidden" for="password">
            Wachtwoord
          </label>

          <input
            class="form-control form-control--center-placeholder"
            type="password"
            id="password"
            name="password"
            v-model="formInput.password"
            placeholder="Vul hier je wachtwoord in"
          />
        </div>

        <div>
          <Button button-type="submit" classes="btn--full-width mb-2">
            Login bij mijn account
          </Button>

          <ButtonLink
            component-type="router-link"
            :to="{ name: 'RequestPasswordReset' }"
            type="default"
          >
            Wachtwoord vergeten?
          </ButtonLink>
        </div>
      </form>
    </div>
  </onboarding-layout>
</template>

<script>
import Errors from '../../components/Errors.vue';
import OnboardingLayout from '../Onboarding/OnboardingLayout.vue';
import Button from '../../components/Button.vue';
import ButtonLink from '../../components/ButtonLink.vue';
import retrieveErrorsFromResponse from '../../helpers/retrieveErrorsFromResponse';

const loginCredentials = {
  email: '',
  password: '',
};

export default {
  name: 'Login',

  components: {
    ButtonLink,
    Button,
    OnboardingLayout,
    Errors,
  },

  data() {
    return {
      errors: [],
      formInput: loginCredentials,
    };
  },

  methods: {
    validate() {
      this.errors = [];

      if (!this.formInput.email) {
        this.errors.push('Voer een e-mailadres in');
      }

      if (!this.formInput.password) {
        this.errors.push('Voer een wachtwoord in');
      }

      return this.errors.length === 0;
    },

    login() {
      if (!this.validate()) {
        return;
      }

      this.$store
        .dispatch('login', this.formInput)
        .then(() => {
          this.$router.push({ name: 'Feed' });
        })
        .catch((err) => {
          if (typeof err !== 'undefined' && typeof err.response !== 'undefined') {
            this.errors = retrieveErrorsFromResponse(err.response);
          } else {
            // eslint-disable-next-line no-console
            console.log(err);
            this.errors = [
              'Deze gegevens zijn onjuist, probeer het opnieuw of neem contact op',
            ];
          }
        });
    },
  },
};
</script>

<style scoped>
.login-container {
  max-width: 25rem;
  margin: 0 auto;
}
</style>
