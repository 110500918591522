<template>
  <component :is="componentType" :to="to" :type="buttonType" :class="classNames">
    <slot></slot>
    <img
      v-if="showArrow && type === 'default'"
      src="../assets/arrow-right-purple.svg"
      alt=""
      aria-hidden="true"
    >
    <img
      v-if="showArrow && type === 'primary'"
      src="../assets/arrow-right-orange.svg"
      alt=""
      aria-hidden="true"
    >
  </component>
</template>

<script>
export default {
  props: {
    buttonType: {
      type: String,
      default: 'button',
    },

    classes: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'primary',
    },

    componentType: {
      type: String,
      default: 'button',
    },

    to: {
      type: [String, Object],
    },

    showArrow: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    classNames() {
      return `${this.classes} btn-link btn-link--${this.type}`;
    },
  },
};
</script>

<style>
.btn-link {
  --button-color: var(--heading-color);
  --button-hover-color: var(--heading-color);
  --button-font-family: var(--default-font-family);
  --button-disabled-opacity: 0.5;
  --button-font-size: 1rem;
  --button-border-radius: 2rem;

  display: inline-block;
  font-family: var(--button-font-family);
  font-weight: 400;
  line-height: 1;
  color: var(--button-color);
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: transparent;
  font-size: var(--button-font-size);
  transition: background-color .2s ease-out, color .2s ease-out;
}

.btn-link:hover {
  color: var(--button-hover-color);
  background-color: var(--button-hover-background-color);
}

.btn-link:focus {
  outline: none;
  box-shadow: var(--button-focus-box-shadow);
}

.btn-link:active {
  box-shadow: var(--button-focus-box-shadow);
}

.btn-link[disabled] {
  pointer-events: none;
  opacity: var(--button-disabled-opacity);
}

.btn-link--primary {
  --button-color: #f06b00;
  --button-hover-color: #f06b00;
}

.btn-link--full-width {
  width: 100%;
}
</style>
